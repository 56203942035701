import {useDisclosure} from '@mantine/hooks';
import {useAuth} from '../../../core/usecases/useAuth';
import {NoLoginAlertModal} from '../../../shared/components/NoLoginAlertModal';
import {useHandleCalendar} from '../usecases/useHandleCalendar';

type Props = {
  accessToken?: string;
  scheduleId: number;
  isRegisteredInital: boolean;
  registeredCountInitial: number;
  isSP?: boolean;
};

export const ScheduleButton: React.FC<Props> = ({
  accessToken = '',
  scheduleId,
  isRegisteredInital,
  registeredCountInitial,
  isSP = false,
}: Props) => {
  const {isSignedIn} = useAuth();
  const [opened, {open, close}] = useDisclosure(false);
  const {isRegistered, handleCalendar} = useHandleCalendar({
    accessToken,
    scheduleId,
    isRegisteredInital,
    registeredCountInitial,
  });

  return (
    <>
      <button
        className={`border-collapse border-[1px] border-primary-1 rounded-4 ${isRegistered ? 'bg-primary-1 text-white' : 'bg-white text-primary-1'} py-[3px] relative ${isSP ? 'w-[73px] h-[26px] pt-[5px]' : 'w-[90px]'} flex justify-center items-center hover:opacity-80`}
        onClick={async () => {
          if (!isSignedIn) {
            open();
            return;
          }

          return handleCalendar();
        }}
      >
        <sup
          className={`text-base absolute ${isSP ? 'top-[3px]' : 'top-[5px]'} left-4`}
        >
          +
        </sup>
        <span className="text-xs leading">
          {isRegistered ? '登録済み' : '予定を追加'}
        </span>
      </button>
      <NoLoginAlertModal opened={opened} onClose={close} />
    </>
  );
};
