import {type AppError, type Result} from '@packages/utils';
import {
  useInfiniteQuery,
  type UseInfiniteQueryResult,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import {addDays, getUnixTime} from 'date-fns';
import {type z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  DeleteCalendarSchema,
  PostScheduleSyncExecSchema,
  PutScheduleSyncStopSchema,
  RegistCalendarSchema,
  GetMyCalendarSchema,
  GetMyScheduleSchema,
  GetMyScheduleStatusSchema,
  DeleteMyScheduleSchema,
} from '../entities/calendar/schema';

export const registCalendar = async ({
  accessToken,
  scheduleId,
  version = DEFAULT_API_VERSION,
}: {
  accessToken: string;
  scheduleId: number;
  version?: string;
}) => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.post(
    `/${version}/recruitment-schedules/${scheduleId}/calendar`,
    RegistCalendarSchema,
    {},
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const deleteCalendar = async ({
  accessToken,
  scheduleId,
  version = DEFAULT_API_VERSION,
}: {
  accessToken: string;
  scheduleId: number;
  version?: string;
}) => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.delete(
    `/${version}/recruitment-schedules/${scheduleId}/calendar`,
    DeleteCalendarSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

/**
 * GetMeRecruitmentSchedules
 */

type GetMyCalendarArgs = {
  version?: string;
  accessToken: string;
};

export type GetMyCalendarSchema = z.infer<typeof GetMyCalendarSchema>;
type ResultGetMyCalendarSchema = Result<GetMyCalendarSchema, AppError>;

type UseGetCalendarOptions = {
  config?: UseQueryOptions<ResultGetMyCalendarSchema>;
} & GetMyCalendarArgs;

export const useGetCalendar = ({
  config,
  accessToken,
}: UseGetCalendarOptions) => {
  return useQuery<ResultGetMyCalendarSchema>({
    ...config,
    queryKey: ['getMyCalendar'],
    async queryFn() {
      return getMyRecruitmentSchedules({
        accessToken,
      });
    },
  });
};

export const getMyRecruitmentSchedules = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
}: {
  accessToken: string;
  version?: string;
}) => {
  defaultClient.setToken(accessToken);
  const path = createPathWithQuery(`/${version}/users/me/right-side-schedule`, {
    after: getUnixTime(addDays(new Date(), 60)),
  });
  const result = await defaultClient.get(path, GetMyCalendarSchema);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

type GetMyScheduleArgs = {
  version?: string;
  accessToken: string;
  queryParams: {
    limit: number;
    page: number;
  };
};

export type GetMyScheduleSchema = z.infer<typeof GetMyScheduleSchema>;
type ResultGetMyScheduleSchema = Result<GetMyScheduleSchema, AppError>;

type UseGetScheduleOptions = {
  config?: UseQueryOptions<ResultGetMyScheduleSchema>;
} & GetMyScheduleArgs;

export const getMySchedules = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
  queryParams,
}: GetMyScheduleArgs) => {
  defaultClient.setToken(accessToken);

  const path = createPathWithQuery(
    `/${version}/users/me/schedule`,
    {...queryParams},
    'none',
  );
  const result = await defaultClient.get(path, GetMyScheduleSchema);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useInfiniteSchedule = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  queryParams,
}: UseGetScheduleOptions): UseInfiniteQueryResult<
  GetMyScheduleSchema,
  AppError
> => {
  if (accessToken) {
    defaultClient.setToken(accessToken);
  }

  return useInfiniteQuery(
    [`MySchedule`, queryParams],
    async ({pageParam = {page: 1}}) => {
      const data = await getMySchedules({
        accessToken,
        version,
        queryParams: {
          ...queryParams,
          page: pageParam.page,
        },
      });
      if (!data?.ok) return undefined;

      return data.value;
    },
    {
      getNextPageParam(lastPage) {
        return lastPage && lastPage?.page > 0
          ? {page: lastPage.page}
          : undefined;
      },
    },
  );
};

type GetMyScheduleStatusArgs = {
  version?: string;
  accessToken: string;
};

export type GetMyScheduleStatusSchema = z.infer<
  typeof GetMyScheduleStatusSchema
>;
type ResultGetMyScheduleStatusSchema = Result<
  GetMyScheduleStatusSchema,
  AppError
>;

type UseGetScheduleStatusOptions = {
  config?: UseQueryOptions<ResultGetMyScheduleStatusSchema>;
} & GetMyScheduleStatusArgs;

export const getMyScheduleStatus = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
}: GetMyScheduleStatusArgs) => {
  defaultClient.setToken(accessToken);

  const path = createPathWithQuery(
    `/${version}/users/me/schedule/sync/status`,
    {},
    'none',
  );
  const result = await defaultClient.get(path, GetMyScheduleStatusSchema);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetMyScheduleStatus = ({
  accessToken,
  version,
  config,
}: UseGetScheduleStatusOptions) => {
  return useQuery<ResultGetMyScheduleStatusSchema>({
    ...config,
    queryKey: ['getMyScheduleStatus'],
    async queryFn() {
      return getMyScheduleStatus({
        accessToken,
        version,
      });
    },
  });
};

export type PostScheduleSyncExecResponse = z.infer<
  typeof PostScheduleSyncExecSchema
>;
export type PostScheduleSyncExecReturnValueType =
  Result<PostScheduleSyncExecResponse>;

type PostScheduleSyncExecArgs = {
  accessToken: string;
  googleAccessToken: string;
  googleRefreshToken: string;
  expiry: string;
  gmail: string;
  version?: string;
};

export const postScheduleSyncExec = async ({
  accessToken,
  googleAccessToken,
  googleRefreshToken,
  expiry,
  gmail,
  version = DEFAULT_API_VERSION,
}: PostScheduleSyncExecArgs): Promise<PostScheduleSyncExecReturnValueType> => {
  defaultClient.setGoogleCalendarToken({
    accessToken,
    googleAccessToken,
    googleRefreshToken,
    expiry,
  });
  const result = await defaultClient.post(
    `/${version}/users/me/schedule/sync/exec`,
    PostScheduleSyncExecSchema,
    {gmail},
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type PutScheduleSyncStopResponse = z.infer<
  typeof PutScheduleSyncStopSchema
>;
export type PutScheduleSyncStopReturnValueType = Result<
  PutScheduleSyncStopResponse,
  AppError
>;

type PutScheduleSyncStopArgs = {
  accessToken: string;
  version?: string;
};

export const putScheduleSyncStop = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: PutScheduleSyncStopArgs): Promise<PutScheduleSyncStopReturnValueType> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.put(
    `/${version}/users/me/schedule/sync/stop`,
    PutScheduleSyncStopSchema,
    {},
  );

  return result;
};

export type DeleteMyScheduleResponse = z.infer<typeof DeleteMyScheduleSchema>;
export type DeleteMyScheduleReturnValueType = Result<
  DeleteMyScheduleResponse,
  AppError
>;

type DeleteMyScheduleArgs = {
  accessToken: string;
  scheduleId: number;
  version?: string;
};

export const deleteMySchedule = async ({
  accessToken,
  scheduleId,
  version = DEFAULT_API_VERSION,
}: DeleteMyScheduleArgs): Promise<DeleteMyScheduleReturnValueType> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.delete(
    `/${version}/users/me/schedule/event/${scheduleId}/delete`,
    DeleteMyScheduleSchema,
  );

  return result;
};
