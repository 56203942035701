import {useCallback, useEffect, useState} from 'react';
import {
  deleteCalendar,
  registCalendar,
} from '../../../core/repositories/calendarRepository';

type Props = {
  accessToken: string;
  scheduleId: number;
  isRegisteredInital: boolean;
  registeredCountInitial: number;
};

export const useHandleCalendar = ({
  accessToken,
  scheduleId,
  isRegisteredInital,
  registeredCountInitial,
}: Props) => {
  const [registeredCount, setRegisteredCount] = useState<number>(
    registeredCountInitial,
  );
  const [isRegistered, setRegistered] = useState<boolean>(isRegisteredInital);

  useEffect(() => {
    setRegisteredCount(registeredCountInitial);
    setRegistered(isRegisteredInital);
  }, [scheduleId]);

  const registCalendarUsecase = async () => {
    const result = await registCalendar({
      accessToken,
      scheduleId,
    });

    if (!result.ok) {
      return;
    }

    setRegistered(true);
    setRegisteredCount(registeredCount + 1);
  };

  const deleteCalendarUsecase = async () => {
    const result = await deleteCalendar({
      accessToken,
      scheduleId,
    });

    if (!result.ok) {
      return;
    }

    setRegistered(false);
    setRegisteredCount(registeredCount - 1);
  };

  const handleCalendar = useCallback(async () => {
    if (isRegistered) {
      return deleteCalendarUsecase();
    }

    return registCalendarUsecase();
  }, [isRegistered]);

  return {
    isRegistered,
    registeredCount,
    handleCalendar,
  };
};
