export const RECRUITMENT_TAGS = {
  OVERSEAS_STUDENT: 1, // 海外大学生向け
  SCIENCE_STUDENT: 2, // 理系学生向け
  ENGINEER: 3, // エンジニア志望向け
};

export const RECRUITMENT_ORDER = {
  RECOMMEND: 'recommend',
  RECENT: 'recent',
  DEADLINE: 'deadline',
  EVENT: 'eventDate',
};

export const RECRUITMENT_CATEGORY = {
  CLIENT: 'client',
  ARCHIVE: 'admin',
};

export const RECRUITMENT_RESOURCE_CONTENT_TYPE = {
  TEXT: 'text/plain',
  MARKDOWN: 'text/markdown',
};

export const RECRUITMENT_DETAIL_RESOURCE_TYPE = {
  CONTENT: 'content',
  LINK: 'link',
};

export const RECRUITMENT_SCHEDULE_WARNING_CODE = {
  OK: 'OK',
  UNDETERMINABLE: 'UNDETERMINABLE',
  ENTRY_DEADLINE_OVER: 'ENTRY_DEADLINE_OVER',
  EVENT_END_OVER: 'EVENT_END_OVER',
};

export const RECRUITMENT_SCHEDULE_WARNING_CTRL = {
  ALWAYS_ON: 'alwaysOn',
  ALWAYS_OFF: 'alwaysOff',
  DEPENDS_ON: 'dependsOn',
};

export const RECRUITMENT_SCHEDULE_WARNING_STATUS = {
  OK: 'OK',
  ENTRY_DEADLINE_OVER: 'ENTRY_DEADLINE_OVER',
  EVENT_END_OVER: 'EVENT_END_OVER',
};

export const RECRUITMENT_TYPE_KEYS = {
  INTERN: 'intern',
  SELECTION: 'selection',
  EVENT: 'event',
  JOIN_EVENT: 'jointEvent',
  GSSKT_EVENT: 'gssktEvent',
  ETC: '',
};

export const RECRUITMENT_TYPE_LABELS = {
  INTERN: 'インターン',
  SELECTION: '本選考',
  EVENT: 'イベント',
  JOIN_EVENT: '合同イベント',
  GSSKT_EVENT: '外就イベント',
  ETC: 'etc',
};
